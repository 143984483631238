/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 *
 * Code generated by @carbon/icon-build-helpers. DO NOT EDIT.
 */
var _16 = {
  "elem": "svg",
  "attrs": {
    "xmlns": "http://www.w3.org/2000/svg",
    "viewBox": "0 0 16 16",
    "fill": "currentColor",
    "width": 16,
    "height": 16
  },
  "content": [{
    "elem": "path",
    "attrs": {
      "d": "M8,1C4.1,1,1,4.1,1,8s3.1,7,7,7s7-3.1,7-7S11.9,1,8,1z M10.7,11.5L4.5,5.3l0.8-0.8l6.2,6.2L10.7,11.5z"
    }
  }, {
    "elem": "path",
    "attrs": {
      "fill": "none",
      "d": "M10.7,11.5L4.5,5.3l0.8-0.8l6.2,6.2L10.7,11.5z",
      "data-icon-path": "inner-path",
      "opacity": "0"
    }
  }],
  "name": "error--filled",
  "size": 16
};

export { _16 as default };
