/**
 * Copyright IBM Corp. 2016, 2020
 *
 * This source code is licensed under the Apache-2.0 license found in the
 * LICENSE file in the root directory of this source tree.
 *
 * Code generated by @carbon/icon-build-helpers. DO NOT EDIT.
 */
var _20 = {
  "elem": "svg",
  "attrs": {
    "xmlns": "http://www.w3.org/2000/svg",
    "viewBox": "0 0 20 20",
    "fill": "currentColor",
    "width": 20,
    "height": 20
  },
  "content": [{
    "elem": "path",
    "attrs": {
      "d": "M10,1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S15,1,10,1z M9.2,5h1.5v7H9.2V5z M10,16c-0.6,0-1-0.4-1-1s0.4-1,1-1\ts1,0.4,1,1S10.6,16,10,16z"
    }
  }, {
    "elem": "path",
    "attrs": {
      "d": "M9.2,5h1.5v7H9.2V5z M10,16c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S10.6,16,10,16z",
      "data-icon-path": "inner-path",
      "opacity": "0"
    }
  }],
  "name": "warning--filled",
  "size": 20
};

export { _20 as default };
